<template lang="pug">
v-dialog(
  v-model="isOpen", 
  width="650px"
)
  template( v-slot:activator="{ on, attrs }" )
    v-text-field(
      :class="inputClass"
      v-model="formattedDates", 
      :label="label", 
      append-icon="mdi-calendar", 
      readonly, 
      outlined
      dense
      v-bind="attrs", 
      v-on="on"
    )
  v-card
    v-card-text
      .d-flex.pt-4
        .flex-grow-1.pr-8
          h5.mt-1 {{ formattedDates }}
          v-divider.mb-4.mt-2
          
          v-list
            v-list-item-group(
              v-model="propsDateSelection"
            )
              v-list-item(
                dense
                :class="'hover:bg-gray-50 rounded'",
                :active-class="'bg-gray-200'"
                v-for="(dateItem, idx) in dateSelections"
                :key="idx"
                @click="setDate(dateItem.value)"
              )
                v-list-item-content.cursor-pointer
                  | {{ dateItem.text }}

        .flex-shrink-1
          v-date-picker(
            @change="propsDateSelection = 5"
            v-model="dates"
            range
            reactive
            color="primary"
          )
    v-card-actions.bg-gray-100
      v-btn(
        @click="close(true)"
        depressed
      ) Cancel
      v-btn(
        @click="close()"
        depressed
        color="primary"
        :disabled="propsDateSelection !== 0 && dates.length !== 2"
      ) Select
</template>

<script>
import { ref, computed } from '@vue/composition-api'

export default {
  name: 'DateRangePickerDialog',

  props: {
    value: Array,
    dateSelection: Number,
    label: String,
    inputClass: String,
  },

  setup(props, { emit, root: { $day } }) {
    const format = 'MMM Do, YYYY';
    const dateSelections = [
      { value: 'alltime', text: 'All Time' },
      { value: 'today', text: 'Today' },
      { value: 'week', text: 'Week' },
      { value: 'month', text: 'Month' },
      { value: 'year', text: 'Year' },
      { value: 'custom', text: 'Custom' }
    ]
    
    // picker
    const propsDateSelection = ref(props.dateSelection);
    const dates = ref(props.value);
    
    const startPropsDateSelection = ref(props.dateSelection)
    const startDates = ref(props.value);

    // view
    const formattedDates = computed(() => {
      if (dates.value.length > 0) {
        const [startDate, endDate] = dates.value;
        const start = $day(startDate).format(format)
        const end = $day(endDate).format(format)
        return [start, end].join(' to ') 
      } else {
        return startPropsDateSelection.value === 0 
          ? 'All Time' : 'Custom';
      }
    });

    const setDate = (selection) => {
      if (['alltime', 'custom'].includes(selection)) {
        dates.value = []
      } else {
        const startDate = $day().startOf(selection).format().substring(0,10);
        const endDate = $day().endOf(selection).format().substring(0,10);
        dates.value = [startDate, endDate]
      }
    }
    const isOpen = ref(false);

    const close = (reset) => {
      if (!reset) {
        emit('input', dates.value)
        emit('selection', propsDateSelection.value)
      } else {
        dates.value = startDates.value;
        propsDateSelection.value = startPropsDateSelection.value;
      }
      
      isOpen.value = false;
    }

    return { 
      close,
      setDate,

      isOpen,

      dates,
      formattedDates,
      
      dateSelections,
      propsDateSelection
    }
  }
}
</script>

<style scoped>
::v-deep .v-date-picker--first-in-range {
  box-shadow: 0 0 0 2px red !important;
}
::v-deep .v-date-picker--last-in-range {
  box-shadow: 0 0 0 2px red !important;
}
</style>